import React, { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import decoded from 'jwt-decode';
import * as Sentry from '@sentry/nextjs';

import { SnackBar } from 'components/widgets';
import Navbar from 'components/navigation/Navbar';
import Footer from 'components/footer/Footer';
import AllyNav from '../navigation/profile/AllyNav';
import ClientNav from '../navigation/profile/ClientNav';
import AdminNav from '../navigation/AdminNav';
import useNetwork from '../hooks/useNetwork';

const LayoutContainer = (props) => {
	const router = useRouter();
	const [isPathProfile, setIsPathProfile] = useState(false);
	const { online } = useNetwork();
	const [mobileAppLinks, setMobileAppLinks] = useState({
		ios_app_link: '',
		android_app_link: ''
	});

	useEffect(() => {
		if (router?.query) {
			const { query } = router;
			setCookie({ ...query });
		}
	}, [router?.query]);

	const {
		children,
		pathname,
		ocult,
		user_type,
		token,
		logged,
		isAffiliation = false,
		// Roles
		isSuper,
		isAdmin,
		isCoordinator,
		isMarketing,
		isLeadOperator,
		isNetworkManager,
		isDoctor,
		isAllyMaster,
		isAllyMatrix,
		isAlly,
		recovery,
		hash,
		isoCode,
		currency,
		actions
	} = props;

	const userDataSentry = useMemo(() => {
		if (token) {
			const { data: { _id, email } } = decoded(token);

		return { _id, email };
		}
		return;
	}, [token]);

	Sentry.setUser({ id: userDataSentry?._id || null, email: userDataSentry?.email || null });

	const setCookie = async (data) => {
		await actions.setCJCookie(data);
	};

	useEffect(() => {
		const paths = [
			'/perfil',
			'/perfil/beneficiarios',
			'/perfil/controles-avanzados',
			'/perfil/gestion-de-pagos',
			// '/perfil/gestion-de-renovacion',
			// '/perfil/actualizar-renovacion',
			// '/perfil/sos',
			// '/perfil/sos/consulta-general/motivo',
			// '/perfil/sos/consulta-general/antecedentes',
			// '/perfil/sos/consulta-general/detalle',
			// '/perfil/sos/consulta-general/datos',
			// '/perfil/sos/consulta-general/resultados',
			// '/perfil/sos/consulta-general/resultadoscontacto',
			// '/perfil/sos/consulta-general/finalizado',
			// '/perfil/sos/covid-19',
			// '/perfil/sos/covid-19/resultados',
			// '/perfil/aliado/afiliaciones',
			'/perfil/aliado/mis-aliados',
			'/perfil/aliado/mi-balance',
			'/perfil/aliado/recursos-de-ventas',
			// '/perfil/aliado/cotizar',
			'/perfil/aliado/premios',
			'/perfil/aliado/mi-bigbroder'
		];
		setIsPathProfile(paths.includes(pathname));
	}, [pathname]);

	useEffect(() => {
		if (router?.query?.promoter) {
			const { promoter, coupon } = router.query;
			const validateLink = async () => {
				const response = await props.actions.validateLink({
					share_link: promoter,
					share_link_discount: coupon
				});

				if (response?.success) {
					const cookieContainer = Cookies.get('allybound');
					if (!cookieContainer)
						Cookies.set(
							'allybound',
							JSON.stringify({
								share_link: promoter,
								share_link_discount: coupon
							}),
							{ expires: 93 }
						);
				}
			};

			validateLink();
		}
	}, [router?.query]);

	useEffect(() => {
		getCurrencies();
	}, [router?.pathname]);

	const getCurrencies = async () => {
		const response = await props.actions.getCurrenciesByCountry();
		if (response?.currencies && (!isoCode || !currency))
			await props.actions.setCurrency(response?.currencies[0]);
	};

	//Get Addons, Intervals , Other Config
	useEffect(() => {
		(async () => {
			const res = await props.actions.getConfig();
			if (res.success) {
				setMobileAppLinks({ ...res.mobileAppLinks });
			}
		})();
	}, []);

	const isMobile = router?.asPath?.includes('/m');

	return (
		<main className="content" style={{ paddingTop: isPathProfile ? '' : ocult || isMobile ? 0 : 72 }}>
			{((!ocult && !user_type && !token && !hash) || recovery) && (
				<Navbar pathname={pathname} isPathProfile={isPathProfile} />
			)}
			{!ocult && (isAlly || isAllyMaster || isAllyMatrix) && (
				<AllyNav pathname={pathname} isPathProfile={isPathProfile} />
			)}
			{!ocult && user_type === 1 && !logged && token && (
				<ClientNav pathname={pathname} isPathProfile={isPathProfile} />
			)}
			{logged &&
				(isSuper ||
					isAdmin ||
					isCoordinator ||
					isMarketing ||
					isLeadOperator ||
					isDoctor ||
					isNetworkManager) && <AdminNav pathname={pathname} />}

			{children}

			{!ocult && (!user_type || user_type === 1) && !isAffiliation && (
				<Footer
					pathname={pathname}
					android_app_link={mobileAppLinks.android_app_link}
					ios_app_link={mobileAppLinks.ios_app_link}
				/>
			)}

			{!online && (
				<SnackBar openAlert messageAlert={<RenderOffline />} isWarning />
			)}
		</main>
	);
};

const RenderOffline = () => (
	<div className="flex items-center">
		<ReportProblemRoundedIcon className="mr-3" />
		<span>No tienes acceso a internet, por favor verifica tu conexión.</span>
	</div>
);

function mapStateToProps(state) {
	const isSuper =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 140;
	const isAdmin =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 120;
	const isCoordinator =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 15;
	const isMarketing =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 50;
	const isLeadOperator =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 10;
	const isDoctor =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 130;
	const isNetworkManager =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 115;

	const isAllyMaster =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 110;
	const isAllyMatrix =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 111;
	const isAlly =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 100;

	const logged = state.login.get('logged');
	const user_type = state.affiliation.get('user_type');
	const token = state.affiliation.get('token');

	const isoCode = state.currencies.get('isoCode');
	const currency = state.currencies.get('currency');

	return {
		isSuper,
		isAdmin,
		isCoordinator,
		isAllyMatrix,
		isAllyMaster,
		isAlly,
		isDoctor,
		isMarketing,
		isLeadOperator,
		isNetworkManager,
		logged,
		user_type,
		token,
		isoCode,
		currency
	};
}

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
