import React, { Fragment } from 'react';
import { useRouter } from 'next/router';
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PhoneIcon from '@material-ui/icons/Phone';
import ManageLead from 'components/home/ManageLead';
import { phoneNumbers } from 'components/utils/PhoneNumbers';

const MegaMenu = ({
	showLead,
	handleSubmitLead,
	showDialog,
	closeDialog,
	hideNumbers,
	actions
}) => {
	const router = useRouter();
	return (
		<>
			<div className="w-full hidden md:flex items-center justify-between py-4 px-5 md:px-20 border-b">
				<div
					className={`flex items-center ${
						hideNumbers ? 'invisible' : 'visible'
					}`}
				>
					<PhoneIcon className="text-base text-pink-500 mr-2" />
					<span className="text-sm font-normal text-gray-750 font-poppins">
						{phoneNumbers.map((phone, index) => (
							<Fragment key={phone.country}>
								{phone.country}:&nbsp;
								<a
									href={`tel:${phone.callNumber}`}
									rel="noopener noreferrer"
									className="font-poppins"
								>
									{phone.number}
								</a>
								{index !== phoneNumbers.length - 1 && (
									<>&nbsp;&nbsp;|&nbsp;&nbsp;</>
								)}
							</Fragment>
						))}
					</span>
				</div>
				{/* <button
					className="lg:hidden hover:bg-pink-500 hover:text-white cursor-pointer border-pink-500 border rounded-lg font-poppins uppercase font-bold px-4 py-1 text-pink-500 text-sm flex justify-center items-center mr-5"
					onClick={() => {
						actions.setMembershipOrigin('sos');
						router.push(
							{ pathname: '/sos', query: { origin: 'sos', type: 'sos-home' } },
							'/sos'
						);
					}}
				>
					SOS
				</button> */}
			</div>
			{/* MOBILE */}
			<div className="w-full md:hidden flex flex-col items-center py-4 border-t">
				<div
					className={`flex items-center mb-3 ${
						hideNumbers ? 'invisible' : 'visible'
					}`}
				>
					<PhoneIcon className="text-base text-pink-500 mr-2" />
					<span className="text-xs font-poppins text-center font-normal text-gray-750">
						{phoneNumbers
							.filter((p) => p.country === 'República Dominicana')
							.map((phone) => (
								<Fragment key={phone.country}>
									{phone.country}:&nbsp;
									<a
										href={`tel:${phone.cellPhone}`}
										rel="noopener noreferrer"
										className="font-poppins"
									>
										{phone.number}
									</a>
								</Fragment>
							))}
					</span>
				</div>
				<div>
					<a
						className="text-base cursor-pointer text-pink-500 font-bold font-poppins mt-4"
						onClick={() => showDialog()}
					>
						¡Te llamamos gratis!
					</a>
				</div>
			</div>
			{showLead && (
				<ManageLead
					handleClose={() => closeDialog()}
					handleSubmit={handleSubmitLead}
				/>
			)}
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(MegaMenu);
