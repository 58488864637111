import React, { useState } from 'react';
import { Toolbar, Popover, Button } from '@material-ui/core';

const CountriesLogin = ({ generateHash }) => {
	const [anchorFlag, setAnchorFlag] = useState(null);

	const handleOpenFlag = (event) => {
		event.preventDefault();
		setAnchorFlag(event.currentTarget);
	};

	const handleCloseFlag = () => {
		setAnchorFlag(null);
	};
	return (
		<>
			<div className="hidden md:block">
				<Button
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleOpenFlag}
				>
					<img
						src="/images/do.svg"
						alt="Bandera de Venezuela"
						className="h-7"
					/>
				</Button>
				<Popover
					id="simple-menu"
					anchorEl={anchorFlag}
					keepMounted
					open={Boolean(anchorFlag)}
					onClose={handleCloseFlag}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<div className="flex flex-col bg-white p-3">
						<span className="cursor-pointer" onClick={() => generateHash('ve')}>
							<img
								src="/images/ve.svg"
								alt="Bandera de Venezuela"
								className="h-7 mb-3"
							/>
						</span>
						<span className="cursor-pointer" onClick={() => generateHash('mx')}>
							<img
								src="/images/mx.svg"
								alt="Bandera de México"
								className="h-7 mb-3"
							/>
						</span>
						<span className="cursor-pointer" onClick={() => generateHash('hn')}>
							<img
								src="/images/hn.svg"
								alt="Bandera de Honduras"
								className="h-7 mb-3"
							/>
						</span>
						<span className="cursor-pointer" onClick={() => generateHash('ph')}>
							<img
								src="/images/ph.svg"
								alt="Bandera de Filipinas"
								className="h-7"
							/>
						</span>
					</div>
				</Popover>
			</div>
			<div className="flex md:hidden flex-row items-center px-4 py-2">
				<span className="cursor-pointer" onClick={() => generateHash('ve')}>
					<img
						src="/images/ve.svg"
						alt="Bandera de Venezuela"
						className="h-7"
					/>
				</span>
				<span className="cursor-pointer" onClick={() => generateHash('mx')}>
					<img
						src="/images/mx.svg"
						alt="Bandera de México"
						className="h-7 ml-3"
					/>
				</span>
				<span className="cursor-pointer" onClick={() => generateHash('hn')}>
					<img
						src="/images/hn.svg"
						alt="Bandera de Honduras"
						className="h-7 ml-3"
					/>
				</span>
				<span className="cursor-pointer" onClick={() => generateHash('ph')}>
					<img
						src="/images/ph.svg"
						alt="Bandera de Filipinas"
						className="h-7 ml-3"
					/>
				</span>
			</div>
		</>
	);
};

export default CountriesLogin;
