import React, { Component } from 'react';
import Router from 'next/router';
import Link from 'next/link';

import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { bindActionCreators } from 'redux';

import { Toolbar } from '@material-ui/core';
import Sidenav from '../Sidenav';
import MegaMenu from '../MegaMenu';
import CountriesLogin from '../../home/CountriesLogin';
import { SnackBar } from 'components/widgets';
import { googleLogout } from '../../utils/GoogleLogout';

const logo = '/static/images/Asistensi_logotipo.svg';

class ClientNav extends Component {
	state = {
		anchorEl: null,
		display: true,
		isProfile: false,
		showLead: false,
		openAlert: false,
		messageAlert: ''
	};

	handleClose = () => this.setState({ anchorEl: null });

	showDisplay = () => this.setState({ display: true, anchorEl: null });

	handleSignOut = () => {
		const { actions } = this.props;
		actions.save_token({ token: '' });
		actions.save_titular_email({ email: '' });
		actions.save_user_type({ user_type: 0 });
		actions.cleanAffiliation();
		actions.setSignupData({});
		googleLogout();
		actions.Logout();
		actions.setMembershipOrigin('login');
		Router.push('/authentication/signIn', '/iniciar-sesion');
	};

	componentDidMount = async () => await this.handleNavbarState();

	async UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.pathname !== this.props.pathname) {
			await this.handleNavbarState();
		}
	}

	handleNavbarState = async () => {
		const pathname = (await this.props.pathname) ? this.props.pathname : '/';
		if (
			pathname === '/perfil' ||
			pathname === '/perfil/beneficiarios' ||
			pathname === '/perfil/controles-avanzados' ||
			pathname === '/perfil/gestion-de-pagos' 
			// pathname === '/perfil/gestion-de-renovacion' ||
			// pathname === '/perfil/actualizar-renovacion' ||
			// pathname === '/perfil/sos' ||
			// pathname === '/perfil/sos/consulta-general/motivo' ||
			// pathname === '/perfil/sos/consulta-general/antecedentes' ||
			// pathname === '/perfil/sos/consulta-general/detalle' ||
			// pathname === '/perfil/sos/consulta-general/datos' ||
			// pathname === '/perfil/sos/consulta-general/resultados' ||
			// pathname === '/perfil/sos/consulta-general/resultadoscontacto' ||
			// pathname === '/perfil/sos/consulta-general/finalizado' ||
			// pathname === '/perfil/sos/covid-19' ||
			// pathname === '/perfil/sos/covid-19/resultados'
		) {
			this.setState({ isProfile: true });
		} else {
			this.setState({ isProfile: false });
		}
	};

	handleSubmitLead = (response) => {
		document.body.classList.remove('modal-open');
		this.setState({ ...response, showLead: false });
	};

	handleCloseAlert = () => this.setState({ openAlert: false });

	generateHash = async (country) => {
		const { actions, token } = this.props;
		const urls = {
			ve: process.env.REACT_APP_DOMAIN_URL_VE,
			mx: process.env.REACT_APP_DOMAIN_URL_MX,
			hn: process.env.REACT_APP_DOMAIN_URL_HN,
			ph: process.env.REACT_APP_DOMAIN_URL_PH,

		};

		if (token) {
			const response = await actions.generateSessionHash();
			window.open(`${urls[country]}?hash=${response.hash}`, '_blank');
		}
	};

	showDialog = () => {
		document.body.classList.add('modal-open');
		this.setState({ showLead: true });
	};

	closeDialog = () => {
		document.body.classList.remove('modal-open');
		this.setState({ showLead: false });
	};

	render() {
		const { titular, user_type, token } = this.props;

		const { display, isProfile, showLead, messageAlert, openAlert } =
			this.state;

		const menu = [
			{ text: '', url: '/', as: '/', targetBlank: false },
			{ text: 'Planes', url: '/plans', as: '/planes', targetBlank: false },
			{
				text: 'Servicios de salud',
				url: '/benefits',
				as: '/beneficios',
				targetBlank: false
			},
			{
				text: 'Quiénes somos',
				url: '/about',
				as: '/quienes-somos',
				targetBlank: false
			},
			{
				text: 'Preguntas frecuentes',
				url: '/faqs',
				as: '/faq',
				targetBlank: false
			},
			{
				text: 'Contacto',
				url: '/contact',
				as: '/contacto',
				targetBlank: false
			},
			{
				text: 'Noticias',
				url: `${process.env.REACT_APP_DOMAIN_URL_BACK}/noticias`,
				as: false,
				targetBlank: true
			},
			// { text: 'SOS', url: '/sos' },
			{
				text: 'Mi cuenta',
				url: '/profile/beneficiaries',
				as: '/perfil/beneficiarios',
				targetBlank: false
			}
		];

		const navigation = menu.map((link, i) => {
			return (
				<li
					key={i}
					className={`text-small lg:text-xs text-gray-750 md:pr-5 lg:pr-8 leading-relaxed hover:text-pink-500 ${
						this.state.ocult === true ? 'hidden' : ''
					}`}
				>
					<Link href={link.url} as={link.as} passHref>
						<a
							className={`
                font-poppins
                ${link.url === '/' ? 'ml-4' : ''}
                ${link.url === '/sos' ? 'text-pink-500' : ''}
                ${
									Router.router &&
									Router.router.pathname &&
									Router.router.pathname === link.url
										? 'text-pink-500'
										: ''
								}
              `}
							target={link.targetBlank ? '_blank' : ''}
							rel={link.targetBlank ? 'noreferrer' : ''}
						>
							{link.text}
						</a>
					</Link>
				</li>
			);
		});

		return (
			<nav className="bg-white shadow-xs font-bold fixed top-0 z-50 w-full flex flex-col">
				{!isProfile && (
					<div className="order-2 md:order-first">
						<MegaMenu
							showDialog={this.showDialog}
							closeDialog={this.closeDialog}
							showLead={showLead}
							handleSubmitLead={this.handleSubmitLead}
						/>
					</div>
				)}
				<Toolbar
					className={`flex justify-between padding-0 ${
						isProfile ? 'container-profile' : 'px-5 md:px-16 lg:px-20'
					}`}
				>
					<Sidenav
						navigation={menu}
						display={display}
						handleSignOut={this.handleSignOut}
						isProfile={true}
					/>
					<div className="justify-between items-center hidden w-full md:flex">
						{this.state.isProfile === true ? (
							<div className="text-small lg:text-xs text-gray-800">
								<span className="font-bold capitalize">
									¡Hola
									{titular && titular.first_name && user_type === 1 && (
										<span> {titular.first_name}</span>
									)}
									!
								</span>
								<span className="font-normal">
									{' '}
									Bienvenid{titular && titular.sex === 'F' ? 'a' : 'o'} a{' '}
								</span>
								<Link href="/">
									<span className="text-pink-500 cursor-pointer">
										asistensi
									</span>
								</Link>
							</div>
						) : (
							<Link href="/">
								<img
									alt="Logo Asistensi"
									src={logo}
									className="w-auto h-10 cursor-pointer"
								/>
							</Link>
						)}
						<ul className="flex items-center justify-evenly">
							{navigation}
							<li>
								<button
									onClick={() => this.handleSignOut()}
									className="white-button-nav min-w-28 text-center text-small lg:text-xs"
								>
									Cerrar Sesión
								</button>
							</li>
							{token && <CountriesLogin generateHash={this.generateHash} />}
						</ul>
					</div>
				</Toolbar>
				<SnackBar
					openAlert={openAlert}
					messageAlert={messageAlert}
					handleClose={this.handleCloseAlert}
				/>
			</nav>
		);
	}
}
function mapStateToProps(state) {
	const titular = state.affiliation.get('titular');
	const user_type = state.affiliation.get('user_type');
	const token = state.affiliation.get('token');

	return {
		titular,
		user_type,
		token
	};
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientNav);
