import React, { Component } from 'react';
import Link from 'next/link';
import Router from 'next/router';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PhoneIcon from '@material-ui/icons/Phone';
import CountriesLogin from '../home/CountriesLogin';

const logo = '/static/images/Asistensi_logotipo.svg';

class Sidenav extends Component {
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.navigation !== this.state.navigation) {
			this.setState({ navigation: nextProps.navigation });
		}
	}

	toggleDrawer = (side, open) => () => {
		this.setState({
			[side]: open
		});
	};

	constructor(props) {
		super();
		this.state = {
			navigation: props.navigation,
			top: false,
			left: false,
			bottom: false,
			right: false
		};

		this.toggleDrawer = this.toggleDrawer.bind(this);
	}

	generateHash = async (country) => {
		const { actions, token } = this.props;
		const urls = {
			ve: process.env.REACT_APP_DOMAIN_URL_VE,
			mx: process.env.REACT_APP_DOMAIN_URL_MX,
			hn: process.env.REACT_APP_DOMAIN_URL_HN,
			ph: process.env.REACT_APP_DOMAIN_URL_PH,
		};

		if (token) {
			const response = await actions.generateSessionHash();
			window.open(`${urls[country]}?hash=${response.hash}`, '_blank');
			return;
		}

		window.open(`${urls[country]}`, '_blank');
	};

	render() {
		const navigation = this.state.navigation.map((link, i) => {
			if (link.text === 'SOS') {
				return (
					<span
						key={i}
						className="cursor-pointer"
						onClick={() => {
							this.props.actions.setMembershipOrigin('sos');
							Router.push(
								{
									pathname: '/sos',
									query: { origin: 'sos', type: 'sos-home' }
								},
								'/sos'
							);
						}}
					>
						<a
							className={`${
								Router.router &&
								Router.router.pathname &&
								Router.router.pathname === link.url
									? 'side-active-link'
									: ''
							}`}
						>
							<ListItem button className="border-left-side-nav">
								<ListItemText>
									<span className="text-purple-500 font-bold font-poppins text-sm hover:text-pink-500">
										{link.text}
									</span>
								</ListItemText>
							</ListItem>
						</a>
					</span>
				);
			} else {
				return (
					<Link href={link.url} as={link.as} prefetch={link.prefetch} key={i}>
						<a
							className={`${
								Router.router &&
								Router.router.pathname &&
								Router.router.pathname === link.url
									? 'side-active-link'
									: ''
							}`}
							target={link.targetBlank ? '_blank' : ''}
							rel={link.targetBlank ? 'noreferrer' : ''}
						>
							<ListItem button className="border-left-side-nav">
								<ListItemText>
									<span className="text-purple-500 font-bold font-poppins text-sm hover:text-pink-500">
										{link.text}
									</span>
								</ListItemText>
							</ListItem>
						</a>
					</Link>
				);
			}
		});

		return (
			<div className="flex items-center flex-col min-h-64p w-full md:hidden">
				<style global jsx>
					{`
						.side-nav-asistensi .border-left-side-nav:hover,
						.side-nav-asistensi .border-left-side-nav:active,
						.side-nav-asistensi .border-left-side-nav:focus {
							border-left: 5px solid #fb314a;
							background: rgba(0, 0, 0, 0);
							transition: all 300ms ease-in-out;
						}

						.side-nav-asistensi .border-left-side-nav:hover div span,
						.side-nav-asistensi .border-left-side-nav:focus div span,
						.side-nav-asistensi .border-left-side-nav:active div span {
							color: #fb314a;
						}

						.side-active-link .border-left-side-nav {
							border-left: 5px solid #fb314a;
						}

						.side-active-link .border-left-side-nav div span {
							color: #fb314a !important;
						}
					`}
				</style>
				<div className="w-full flex justify-between py-4">
					<Link href="/">
						<img
							alt="Logo Asistensi"
							src={logo}
							className="h-10 w-auto cursor-pointer"
						/>
					</Link>
					<button
						className="hover:bg-purple-500 p-1 rounded transition duration-300 ease-in"
						onClick={this.toggleDrawer('right', true)}
					>
						<MenuIcon className="text-purple-500 hover:text-white" />
					</button>
				</div>
				<Drawer
					anchor="right"
					open={this.state.right}
					onClose={this.toggleDrawer('right', false)}
					className="side-nav-asistensi"
				>
					<div
						tabIndex={0}
						role="button"
						onClick={this.toggleDrawer('right', false)}
						onKeyDown={this.toggleDrawer('right', false)}
					>
						<List>
							<div className="w-screen sm:w-full">
								<div className="w-screen sm:w-full flex justify-end">
									<button
										className="mr-2 p-2 rounded-full hover:bg-gray-200 transition duration-100 ease-in"
										onClick={this.toggleDrawer('right', false)}
									>
										<CloseIcon className="text-purple-500" />
									</button>
								</div>

								{navigation}

								{!this.props.isProfile && (
									<>
										<Link href="/privacypolicy" as="/politica-privacidad">
											<ListItem button className="border-left-side-nav">
												<ListItemText>
													<span className="text-purple-500 font-bold font-poppins text-sm">
														Políticas de privacidad
													</span>
												</ListItemText>
											</ListItem>
										</Link>

										<Link href="/termscondition" as="/terminos-uso">
											<ListItem button className="border-left-side-nav">
												<ListItemText>
													<span className="text-purple-500 font-bold font-poppins text-sm">
														Términos de uso
													</span>
												</ListItemText>
											</ListItem>
										</Link>

										<div className="flex justify-between px-4 mt-4">
											<Link
												href="/authentication/signUp"
												as="/registro-cliente"
											>
												<button
													className="secondary-button-nav min-w-32 mr-4"
													onClick={() =>
														this.props.actions.setMembershipOrigin('join')
													}
												>
													Suscribirse
												</button>
											</Link>

											<Link href="/authentication/signIn" as="/iniciar-sesion">
												<button className="white-button-nav min-w-32">
													Iniciar Sesión
												</button>
											</Link>
										</div>
									</>
								)}

								{this.props.isProfile && (
									<ListItem button className="border-left-side-nav">
										<ListItemText onClick={this.props.handleSignOut}>
											<span className="text-purple-500 font-bold font-poppins text-sm">
												Cerrar Sesión
											</span>
										</ListItemText>
									</ListItem>
								)}
								{this.props.token && (
									<div className="mt-3">
										<CountriesLogin generateHash={this.generateHash} />
									</div>
								)}
							</div>
						</List>
					</div>
				</Drawer>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.affiliation.get('token')
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
