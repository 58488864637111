export const phoneNumbers = [
	{
		country: 'República Dominicana',
		number: '+1 (809) 547 7007',
		callNumber: '+18095477007'
	},
	{
		country: 'Estados Unidos',
		number: '+1 (917) 909 5661',
		callNumber: '+19179095661'
	},
	{
		country: 'España',
		number: '+34 (911) 231 079',
		callNumber: '+34911231079'
	}
];
