import React, { Component } from 'react';
import Router from 'next/router';
import Link from 'next/link';

import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { bindActionCreators } from 'redux';

import { Toolbar } from '@material-ui/core';
import Sidenav from '../Sidenav';
import MegaMenu from '../MegaMenu';
import CountriesLogin from '../../home/CountriesLogin';
import { SnackBar } from 'components/widgets';
import { googleLogout } from '../../utils/GoogleLogout';

const logo = '/static/images/Asistensi_logotipo.svg';

class AllyNav extends Component {
	state = {
		anchorEl: null,
		display: true,
		isProfile: false,
		showLead: false,
		openAlert: false,
		messageAlert: ''
	};

	handleClose = () => this.setState({ anchorEl: null });

	showDisplay = () => this.setState({ display: true, anchorEl: null });

	handleSignOut = () => {
		this.props.actions.save_token({ token: '' });
		this.props.actions.save_titular_email({ email: '' });
		this.props.actions.save_user_type({ user_type: 0 });
		this.props.actions.cleanAffiliation();
		googleLogout();
		this.props.actions.Logout();
		this.props.actions.setMembershipOrigin('login');
		Router.push('/authentication/signIn', '/iniciar-sesion');
	};

	componentDidMount = async () => {
		await this.handleNavbarState();

		// class is lost in close session button with this it is fixed
		const element = document.querySelector(
			'#__next > div > div > main > nav > div > div > ul > button'
		);
		if (element) element?.setAttribute('class', 'white-button-nav min-w-28');
	};

	async UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.pathname !== this.props.pathname) {
			await this.handleNavbarState();
		}
	}

	handleNavbarState = async () => {
		const pathname = (await this.props.pathname) ? this.props.pathname : '/';
		if (
			pathname === '/perfil/aliado/afiliaciones' ||
			pathname === '/perfil/aliado/mis-aliados' ||
			pathname === '/perfil/aliado/mi-balance' ||
			pathname === '/perfil/aliado/recursos-de-ventas' ||
			pathname === '/perfil' ||
			pathname === '/perfil/beneficiarios' ||
			pathname === '/perfil/controles-avanzados' ||
			pathname === '/perfil/gestion-de-pagos' ||
			pathname === '/perfil/gestion-de-renovacion' ||
			pathname === '/perfil/actualizar-renovacion' ||
			pathname === '/perfil/aliado/cotizar' ||
			pathname === '/perfil/aliado/premios' ||
			pathname === '/perfil/aliado/mi-bigbroder'
		) {
			this.setState({ isProfile: true });
		} else {
			this.setState({ isProfile: false });
		}
	};

	handleSubmitLead = (response) => {
		document.body.classList.remove('modal-open');
		this.setState({ ...response, showLead: false });
	};

	handleCloseAlert = () => this.setState({ openAlert: false });

	generateHash = async (country) => {
		const { actions, token } = this.props;
		const urls = {
			ve: process.env.REACT_APP_DOMAIN_URL_VE,
			mx: process.env.REACT_APP_DOMAIN_URL_MX,
			hn: process.env.REACT_APP_DOMAIN_URL_HN,
			ph: process.env.REACT_APP_DOMAIN_URL_PH,
		};

		if (token) {
			const response = await actions.generateSessionHash();
			window.open(`${urls[country]}?hash=${response.hash}`, '_blank');
		}
	};

	showDialog = () => {
		document.body.classList.add('modal-open');
		this.setState({ showLead: true });
	};

	closeDialog = () => {
		document.body.classList.remove('modal-open');
		this.setState({ showLead: false });
	};

	render() {
		const {
			isAlly,
			isAllyMaster,
			isAllyMatrix,
			great_ally_id,
			company_name,
			full_name,
			no_aply_commission,
			is_promoter,
			token
		} = this.props;

		const { display, isProfile, showLead, messageAlert, openAlert } =
			this.state;

		let menu = [
			{ text: 'Planes', url: '/plans', as: '/planes' },
			{ text: 'Servicios de salud', url: '/benefits', as: '/beneficios' },
			{ text: 'Preguntas frecuentes', url: '/faqs', as: '/faq' },
			{
				text: 'Recursos',
				url: '/profile/ally/resources',
				as: '/perfil/aliado/recursos-de-ventas'
			},
			{
				text: 'Recomendaciones',
				url: '/profile/ally/affiliations',
				as: '/perfil/aliado/afiliaciones'
			}
		];

		if (isAllyMaster || isAllyMatrix || (isAlly && !no_aply_commission)) {
			let profileRoute = {
				text: 'Mi perfil',
				url: '/profile/beneficiaries',
				as: '/perfil/beneficiarios'
			};
			if ((isAllyMaster && great_ally_id) || isAllyMatrix)
				profileRoute = { text: 'Mi perfil', url: '/profile', as: '/perfil' };
			menu.push(profileRoute);
			if (isAllyMatrix && is_promoter) delete menu[3];
		}

		const navigation = menu.map((link, i) => {
			return (
				<li
					key={i}
					className={`text-xs text-gray-800 md:pr-5 lg:pr-8 leading-relaxed hover:text-pink-500 ${
						this.state.ocult === true ? 'hidden' : ''
					}`}
				>
					<Link href={link.url} as={link.as} passHref>
						<a
							className={`
              nav-text
              ${
								Router.router &&
								Router.router.pathname &&
								Router.router.pathname === link.url
									? 'text-pink-500'
									: ''
							}`}
						>
							{link.text}
						</a>
					</Link>
				</li>
			);
		});

		return (
			<nav className="bg-white shadow-xs font-bold fixed top-0 z-50 w-full flex flex-col">
				{!isProfile && (
					<div className="order-2 md:order-first">
						<MegaMenu
							showDialog={this.showDialog}
							closeDialog={this.closeDialog}
							showLead={showLead}
							handleSubmitLead={this.handleSubmitLead}
						/>
					</div>
				)}
				<Toolbar
					className={`flex justify-between padding-0 ${
						isProfile ? 'container-profile' : 'container'
					}`}
				>
					<Sidenav
						navigation={menu}
						display={display}
						handleSignOut={this.handleSignOut}
						isProfile={true}
					/>
					<div className="flex justify-between items-center hidden w-full md:flex">
						{this.state.isProfile === true ? (
							<div className="text-xs text-gray-800">
								<span className="font-bold capitalize">
									¡Hola{' '}
									<span>
										{(isAllyMatrix && !is_promoter) ||
										(isAllyMaster && great_ally_id)
											? company_name
											: full_name}
									</span>
								</span>
								<span className="font-normal">
									{' '}
									Bienvenid
									{this.props.titular && this.props.titular.sex === 'F'
										? 'a'
										: 'o'}{' '}
									a{' '}
								</span>
								<Link href="/">
									<span className="text-pink-500 cursor-pointer">
										asistensi
									</span>
								</Link>
							</div>
						) : (
							<Link href="/">
								<img
									alt="Logo Asistensi"
									src={logo}
									className="w-auto h-12 cursor-pointer"
								/>
							</Link>
						)}
						<ul className="flex items-center justify-evenly">
							{navigation}

							<button
								onClick={() => this.handleSignOut()}
								className="white-button-nav min-w-28"
							>
								Cerrar Sesión
							</button>

							{token && <CountriesLogin generateHash={this.generateHash} />}
						</ul>
					</div>
				</Toolbar>
				<SnackBar
					openAlert={openAlert}
					messageAlert={messageAlert}
					handleClose={this.handleCloseAlert}
				/>
			</nav>
		);
	}
}

function mapStateToProps(state) {
	const isAllyMaster =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 110;
	const isAllyMatrix =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 111;
	const isAlly =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 100;
	const great_ally_id = state.login.get('great_ally_id');
	const no_aply_commission = state.login.get('no_aply_commission');
	const company_name = state.login.get('company_name');
	const full_name = state.login.get('full_name');
	const is_promoter = state.login.get('is_promoter');
	const token = state.affiliation.get('token');

	return {
		isAllyMatrix,
		isAllyMaster,
		isAlly,
		great_ally_id,
		no_aply_commission,
		company_name,
		full_name,
		is_promoter,
		token
	};
}
function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AllyNav);
