import React, { Component } from 'react';
import Link from 'next/link';
import { Toolbar } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';

import { phoneNumbers } from 'components/utils/PhoneNumbers';
import { actualYear } from '../utils/Date';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;

const apple = `${serverUrlImages}/icons/apple.svg`;
const android = `${serverUrlImages}/icons/google-play.svg`;
const logo = '/static/images/logo.svg';

const facebook = `${serverUrlImages}/icons/facebook.svg`;
const linkedIn = `${serverUrlImages}/icons/linked-in.svg`;
const twitter = `${serverUrlImages}/icons/twitter.svg`;
const instagram = `${serverUrlImages}/icons/instagram.svg`;

class Footer extends Component {
	state = {
		ocult: false,
		sitemap: [
			{ text: '¿Quiénes somos?', href: '/about', as: '/quienes-somos' },
			{ text: 'Planes', href: '/plans', as: '/planes' },
			{ text: 'Beneficios', href: '/benefits', as: '/beneficios' },
			// { text: 'Cotizar un plan', href: '/quotation', as: '/cotizacion' },
			{ text: 'Contacto', href: '/contact', as: '/contacto' }
		],
		information: [
			{ text: 'Preguntas Frecuentes', href: '/faqs', as: '/faq' },
			{
				text: 'Términos de uso',
				href: '/termscondition',
				as: '/terminos-uso',
				showXS: true
			},
			{
				text: 'Políticas de privacidad',
				href: '/privacypolicy',
				as: '/politica-privacidad',
				showXS: true
			},
			{
				text: 'Políticas de cookies',
				href: '/cookiespolicy',
				as: '/politica-cookies',
				showXS: true
			},
			{
				text: 'Aviso legal',
				href: '/legalnotice',
				as: '/legales',
				showXS: true
			}
		]
	};

	componentDidMount = async () => await this.handleFooterState();

	async UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.pathname !== this.props.pathname) {
			await this.handleFooterState();
		}
	}

	handleFooterState = async () => {
		const pathname = (await this.props.pathname) ? this.props.pathname : '/';

		if (
			pathname === '/comenzar-afiliacion' ||
			pathname === '/iniciar-sesion' ||
			pathname === '/registro-cliente' ||
			pathname === '/registro-cliente-opcional' ||
			// Profile
			pathname === '/perfil' ||
			pathname === '/perfil/beneficiarios' ||
			pathname === '/perfil/controles-avanzados' ||
			// pathname === '/perfil/gestion-de-pagos' ||
			// pathname === '/perfil/gestion-de-renovacion' ||
			// pathname === '/perfil/actualizar-renovacion' ||
			// pathname === '/perfil/sos' ||
			// pathname === '/perfil/sos/consulta-general/motivo' ||
			// pathname === '/perfil/sos/consulta-general/antecedentes' ||
			// pathname === '/perfil/sos/consulta-general/detalle' ||
			// pathname === '/perfil/sos/consulta-general/datos' ||
			// pathname === '/perfil/sos/consulta-general/resultados' ||
			// pathname === '/perfil/sos/consulta-general/resultadoscontacto' ||
			// pathname === '/perfil/sos/consulta-general/finalizado' ||
			// pathname === '/perfil/sos/covid-19' ||
			// pathname === '/perfil/sos/covid-19/resultados' ||
			// Afilliation
			pathname === '/afiliacion-informacion-pago' ||
			pathname === '/afiliacion-flujo-alterno' ||
			// pathname === '/cotizacion' ||
			pathname === '/proceso-finalizado' ||
			// Ally profile
			pathname === '/perfil/aliado/referidos' ||
			pathname === '/perfil/aliado/mis-aliados' ||
			pathname === '/perfil/aliado/mis-ganancias' ||
			pathname === '/perfil/aliado/descargables' ||
			// pathname === '/perfil/aliado/cotizar' ||
			pathname === '/perfil/aliado/premios' ||
			pathname === '/perfil/aliado/mi-bigbroder' ||
			// App routes
			pathname === '/m/terminos-uso' ||
			pathname === '/m/politica-privacidad'
		) {
			this.setState({ ocult: true });
		} else {
			this.setState({ ocult: false });
		}
	};

	render() {
		const {android_app_link, ios_app_link} = this.props;

		return (
			<footer
				className={`static bg-purple-500 ${
					this.state.ocult === true ? 'hidden' : ''
				}`}
			>
				<Toolbar className="container flex flex-col py-16 padding-x-0">
					<img
						src={logo}
						alt="logo asistensi"
						className="h-10 w-auto sm:hidden"
					/>
					<div className="flex w-full pb-14 mt-6 items-start flex-wrap sm:flex-wrap md:flex-no-wrap">
						<div className="w-full items-center flex flex-col text-base md:w-3/5 md:items-start ">
							<p className="text-white md:pr-20 text-justify text-14px">
								Los seguros que otorga Humano Seguros, S.A., empresa debidamente
								autorizada para operar en República Dominicana mediante
								Resolución número 3-87 emitida por la Superintendencia de
								Seguros de la República Dominicana, están asociados a los planes
								de salud que comercializa Asistensi Sucursal Dominicana S.R.L. y
								se encuentran debidamente supervisados por la Superintendencia
								de Seguros de la República Dominicana.
							</p>

							<div className="flex flex-col ">
								<div className="flex items-center flex-wrap mt-6 ">
									<a
										className="footer-button w-32 sm:w-56 mt-4 flex justify-center items-center mr-5 text-base "
										target="_blank"
										href={ios_app_link}
										rel="noreferrer"
									>
										<img
											src={apple}
											alt="icono de apple"
											className="w-4 mr-3"
										/>
										<span className="sm:hidden">iPhone</span>
										<span className="hidden sm:inline-block">App Store</span>
									</a>
									<a
										className="footer-button w-32 sm:w-56 mt-4 flex justify-center items-center text-base "
										target="_blank"
										href={android_app_link}
										rel="noreferrer"
									>
										<img
											src={android}
											alt="icono de apple"
											className="w-4 mr-3"
										/>
										<span className="sm:hidden">Android</span>
										<span className="hidden sm:inline-block">Google Play</span>
									</a>
								</div>

								<a
									href="https://www.asistensi.com/"
									target="_blank"
									className="text-center text-white text-sm mt-8 cursor-pointer md:text-left"
									rel="noreferrer"
								>
									<PublicIcon className="text-white mr-2" />
									Visita <b>asistensi global</b>
								</a>
							</div>
						</div>

						<div className="flex flex-1 flex-col ">
							<div className="flex flex-1 flex-row mb-5">
								<div className="flex flex-col w-full text-base mt-8 sm:w-1/2 sm:mt-12 md:w-1/2 md:mt-0 items-start">
									<span className="text-pink-500 font-bold poppins m-b20 hidden text-lg mb-4 sm:inline-block">
										Mapa de sitio
									</span>
									{this.state.sitemap.map((inf, i) => (
										<Link key={i} href={inf.href} as={inf.as}>
											<a className="text-white leading-relaxed cursor-pointer mt-2">
												{inf.text}
											</a>
										</Link>
									))}
								</div>

								<div className="flex flex-col w-full text-base mt-8 sm:w-1/2 sm:mt-12 md:w-1/2 md:mt-0 items-start">
									<span className="text-pink-500 font-bold poppins m-b20 hidden text-lg mb-4 sm:inline-block ">
										Información
									</span>
									{this.state.information.map((inf, i) => (
										<Link key={i} href={inf.href} as={inf.as}>
											<a
												className={
													'text-white leading-relaxed cursor-pointer mt-2'
												}
											>
												{inf.text}
											</a>
										</Link>
									))}
								</div>
							</div>

							{phoneNumbers.map(({ country, number, cellPhone }) => (
								<div key={country} className="text-white text-left text-sm">
									{country}:
									<a
										href={`tel:${cellPhone}`}
										rel="noopener noreferrer"
										className="ml-1 text-pink-500"
									>
										{number}
									</a>
								</div>
							))}
						</div>
					</div>

					<div className="w-full flex flex-col justify-between items-center mt-8 border-t border-white border-opacity-25 pt-8 sm:flex-row md:pt-16 md:mt-16 md:flex-row">
						<div className="flex sm:order-2 md:order-3 md:items-center">
							<a
								href="https://www.facebook.com/Asistensird-106592334743542/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={facebook}
									alt="logo facebook"
									className="w-5 cursor-pointer mr-5 hover:opacity-50 transition duration-300 ease"
								/>
							</a>
							<a
								href="https://www.linkedin.com/company/asistensi"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={linkedIn}
									alt="logo linked in"
									className="w-5 cursor-pointer mr-5 ml-5 hover:opacity-50 transition duration-300 ease"
								/>
							</a>
							<a
								href="https://twitter.com/Asistensi_rd?s=09"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={twitter}
									alt="logo twitter"
									className="w-5 cursor-pointer mr-5 ml-5 hover:opacity-50 transition duration-300 ease"
								/>
							</a>
							<a
								href="https://instagram.com/asistensi.rd?igshid=l2ga8u4etofs"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={instagram}
									alt="logo instagram"
									className="w-5 cursor-pointer ml-5 hover:opacity-50 transition duration-300 ease"
								/>
							</a>
						</div>
						<img
							src={logo}
							alt="logo asistensi"
							className="h-10 w-auto hidden order-2 md:inline-block"
						/>
						<span className="text-white text-xs mt-8 sm:mt-0">
							©{actualYear} <span className="font-bold">Asistensi</span> Global
							Insurance, Inc.
						</span>
					</div>
				</Toolbar>
			</footer>
		);
	}
}

export default Footer;
